.search-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc; /* Add a border for better visibility */
  border-radius: 4px; /* Optional: rounded corners */
  padding: 5px; /* Optional: spacing around content */
  width: 360px;
  height: 70px;
  margin-left: 30px;
  margin-top: 20px;
}

.search-icon {
  margin-right: 10px; /* Spacing between icon and input */
  color: #aaa; /* Icon color */
  font-size: 25px;
}

.search-input {
  border: none !important; /* Remove default input border */
  outline: none; /* Remove outline on focus */
  flex: 1; /* Allow the input to take the remaining space */
  height: 60px;
}
.category-list {
  padding-left: 20px; /* Adds some indentation */
  margin: 10px 0; /* Adjusts the margin for the list */
}
.category-list li {
  margin-bottom: 5px; /* Optional: Adds space between list items */
}
.category-list li:hover {
  color: blue;
}
.tag-col {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
.tag-row button {
  border: none;
  background-color: white;
  margin: 10px 20px;
  cursor: pointer;
}
.slide-search {
  width: 450px;
  margin-left: 50px;
  margin-top: 30px;
  background-color: #ebeaea;
  margin-bottom: 30px;
}
.slide-search h4 {
  margin-left: 30px;
  padding-top: 50px;
}
.blog-slide {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.card-zoom {
  transition: transform 0.3s ease; /* Smooth transition for the whole card */
}
.card-zoom:hover .blogs-image {
  transform: scale(0.95); /* Scale down the image slightly on hover */
  transition: transform 0.3s ease; /* Smooth transition for the image */
}

.blogs-image {
  transition: transform 0.3s ease; /* Smooth transition for the image */
}
.blogs-img:hover {
  transform: scale(0.95); /* Scale down the image slightly on hover */
  transition: transform 0.3s ease; /* Smooth transition for the image */
}
.cmt-like {
  display: flex;
}
.cmts {
  margin-left: 30px;
}



.blog-col {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.blog-card {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  margin-left: 5px !important;
}



.blog-image-wrapper {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.blog-main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-content {
  padding: 15px;
  text-align: center;
}

.blog-title {
  font-size: 1.2rem;
  color: #333;
}

.blog-author {
  font-size: 0.9rem;
  color: #666;
}

.blog-domain {
  font-size: 0.9rem;
  color: #888;
}

.blog-description {
  font-size: 1rem;
  color: #444;
}

/* Mobile View (max-width: 576px) */
@media (max-width: 576px) {
  .blog-col {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .blog-card {
    max-width: 90%;
  }

  .blog-title {
    font-size: 1rem;
  }

  .blog-description {
    font-size: 0.9rem;
  }
}

/* Tablet View (min-width: 577px and max-width: 992px) */
@media (min-width: 577px) and (max-width: 992px) {
  .blog-col {
    width: 50%;
    display: flex;
    justify-content: center;
  }

  .blog-card {
    max-width: 95%;
  }

  .blog-title {
    font-size: 1.1rem;
  }

  .blog-description {
    font-size: 0.95rem;
  }
}

/* Laptop & Desktop View (min-width: 993px) */
@media (min-width: 993px) {
  .blog-col {
    width: 33.33%;
    display: flex;
    justify-content: center;
  }

  .blog-card {
    max-width: 100%;
  }
}
