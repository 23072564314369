.navbar-container {
  z-index: 100;
  position: fixed;
  width: 100%;
  top: 0;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 255, 255);
  padding: 10px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.logo {
  height: 50px;
}

.navBar_popupCls{
  position: absolute;
  background-color: white;
  // border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
  right:1px
}

.nav-links {
  display: flex;
  color: black;
  gap: 20px;

  &::selection {
    color: #007bff;

  }
}

.nav-links a{
  text-decoration: none;
  color: black;
  font-weight: bold;
  transition: color border 0.2s;
  margin-right: 20px;
  padding-bottom: 5px;
  position: relative;

  &:hover {
    color: #302b63;
  }
  &.active {
    color: #302b63;
  }
  &.active::after,
  &:hover::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100%;
    background: linear-gradient(90deg, #7272b4, #302b63);
    border-radius: 2px;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 0;
    background: linear-gradient(90deg, #7272b4, #302b63);
    border-radius: 2px;
    transition: width 0.3s ease;
  }
}



.auth-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.btns {
  padding: 7px 16px;
  border: none;
  border-radius: 25px;
  font-weight: bold;
  color: #302b63 !important;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-color: white;

  &:hover {
  // background: linear-gradient(135deg, #7272b4  0%,  #302b63 100%);
    background-color: white !important;
    // color: white !important;
    color: #0f0c29 !important;
    transition:all 0.3s ease-in;
  }

  &.primary {
  background: linear-gradient(135deg, #7272b4  0%,  #302b63 100%);
    transition: background-color 0.3s ease-in;
    color: white !important;

    &:hover {
      background-color: #0056b3;
      transform: translateY(-2px);
    }
  }

  // &.logout {
  //   background-color: transparent;
  //   color: red;
  //   border: 1px solid red;
  // }
}
// .btn {
//   padding: 8px 12px;
//   border: none;
//   border-radius: 4px;
//   cursor: pointer;
// }

// .btn.primary {
//   background-color: #007bff;
//   color: white;
// }

// .btn.logout {
//   background-color: transparent;
//   color: red;
// }

.mobile-navbar {
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
}

.hamburger {
  font-size: 24px;
  cursor: pointer;
}

.offcanvas {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100vh;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 1000;
  transition: transform 0.3s ease;
  transform: translateX(100%);

  &.show {
    transform: translateX(0);
  }
}

.close-button {
  align-self: flex-end;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

@media (max-width: 768px) {
  .navbar {
    display: none;
  }
  .mobile-navbar {
    display: flex;
  }
}
.offcanvas {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100vh;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 1000;
  transition: transform 0.3s ease;
  transform: translateX(100%);

  &.show {
    transform: translateX(0);
  }
}

.theme-select {
  display: flex;
  gap: 10px;
  align-items: center; 
  // position: absolute;
  // right: 190px;
  // top: 50%;
  // transform: translateY(-50%);
}

.theme-select label {
  margin-left: 5px;
  display: inline;
  float: left; /* Add some space between radio button and label */
}

@media (max-width: 768px) {
  .theme-select {
    // right: 10px; /* Adjust the position on smaller screens */
    gap: 8px; /* Reduce the gap between the buttons for mobile */
  }
}

// .theme-select {
//   // width: 90px;
//   position: absolute;
//   right: 204px;
//   display: flex;
// }
// select.theme-select {
//   width: 90px;
//   position: absolute;
//   right: 204px;
// }


