.navlink-color-course {
  color: gray;
  transition: color 0.3s ease-in-out;
}

.navlink-color-course:hover {
  color: black;
}

.navlink-color-course.active {
  font-weight: bold;
  color: #28a745 !important;
  border-bottom: 2px solid #28a745;
}

.course-navbar {
  padding: 10px 20px;
}

@media (max-width: 768px) {
  .course-navbar {
    flex-direction: row;
    align-items: center;
    text-align: center;

  }



  .course-navbar .nav {
    flex-direction: row;
    gap: 10px;
  }

  .navlink-color-course {
    display: block;
    width: 23%;
  }

  .enroll-btn-course {
    width: 30%;
    text-align: center;

  }
}


@media (max-width: 576px) {
  .course-navbar {
    flex-direction: row;
    align-items: center;
    text-align: center;
  }


  .course-navbar .nav {
    flex-direction: row;
    gap: 10px;
  }

  .navlink-color-course {
    display: block;
    width: 25%;
  }

  .enroll-btn-course {
    width: 50%;
    text-align: center;

    margin-left: auto;
    margin-right: auto;
  }
}

.container-fluid.coursepage {
  padding: 0;
}

.container-fluid.top-text-course {
  padding: 0;
  background-color: #0d2129;
  height: 100vh;
  padding-top: 70px;
}

.navlink-color-course {
  position: relative;
  text-decoration: none;
  color: white !important;
  //  height: 35px;
}

.navlink-color-course::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #28a745;
  transition: all 0.3s ease-in-out;
  transform: translateX(-50%);
}

// .navlink-color-course:hover::after {
//   width: 80%;
// }

a.navlink-color-course.nav-link {
  color: #b8bdbf;
}

h6.training-program-text {
  color: #b8bdbf;
}

h1.course-title {
  color: white;
}

p.course-descrption-text {
  font-size: 18px;
  color: white;
  width: 46%;
}

.course-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60vh;
  justify-content: center;
}

.course-navbar {
  // height: 90px;
  align-items: center;
  background-color: #0d2129;
  z-index: 10;
  width: 100%;
  padding: 0px !important;
  // border-bottom: 1px solid #343a40;

  position: fixed;
}

button.enroll-btn-course.btn.btn-success {
  border-radius: 8px;
}

.container.feature-container {
  display: flex;
  padding: 350px 150px;
  column-gap: 20px;
  padding-bottom: 30px;
}

.col-md-6.common-style-feature {
  padding: 15px;
  border: 1px solid #b8bdbf;
  max-width: 330px;
  display: flex;
  border-radius: 10px;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.col-md-6.common-style-feature:hover {
  transform: rotateZ(-3deg) translateY(-4px);
  box-shadow: 5px 10px 20px #b7bdbf;
}

h5.feature-text-heading {
  margin: 0;
  font-family: inter;
  font-weight: 700;
}

p.course-feature-text {
  font-size: 18px;
}

.tabs-header-topics {
  display: flex;
  justify-content: center;
  flex-direction: row;
  column-gap: 30px;
}

.tab-topics {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  opacity: 0.3;
}

.icon-container-tab {
  padding: 10px;
  border-radius: 36px;
}

.tab.active-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;

  .icon-container-tab {
    border: 1px solid #dedede;
    padding: 10px;
    border-radius: 36px;
  }
}

.admission-container {
  text-align: center;
  padding: 50px 20px;
  padding-bottom: 10%;

}

.admission-title {
  font-size: 36px;
  font-weight: bold;
  color: #000;
}

.admission-subtitle {
  font-size: 18px;
  color: #555;
  margin-bottom: 40px;
}

.register-card {
  background: #4caf79;
  color: white;
  padding: 30px;
  border-radius: 12px;
  width: 500px;
  height: 250px;
  display: flex;
  margin: 0 auto;
  position: relative;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.register-card::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
}

.register-card::after {
  content: "";
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 100px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.register-title {
  font-size: 40px;
  font-weight: bold;
  color: white;
}

.register-date {
  font-size: 20px;
  margin-bottom: 20px;
}

.register-button {
  background: white;
  color: #4caf79;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
}

.register-button:hover {
  background: #e8f5e9;
}

.sticky-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  padding: 15px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #ddd;
}

.registration-text {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.register-btn {
  background: #4caf79;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s;
}

.register-btn:hover {
  background: #45a066;
}

@media (max-width: 768px) {
  .sticky-footer {
    flex-direction: column;
    text-align: center;
    padding: 15px;
  }

  .register-btn {
    margin-top: 10px;
  }
}



.syllabus-item {
  display: flex;
  align-items: flex-start;
}

.syllabus-index {
  width: 80px;
  height: 80px;
  font-size: 18px;
  font-weight: bold;
  background-color: #ebf7f1;
  color: #49bf84;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.syllabus-content {
  max-width: 80%;
}

.syllabus-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  text-align: start;
}

.syllabus-description {
  font-size: 14px;
  color: #666;
  text-align: start;
}

.feature-text.text-center {
  display: flex;
  align-items: center;

}

.row.feature-flex-container {
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
}

.syllabus-item.d-flex.align-items-start.mb-4 {
  border: 1px solid #dedede;
  padding: 42px;
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.syllabus-item.d-flex.align-items-start.mb-4:hover {
  transform: rotateZ(-3deg) translateY(-5px);
  box-shadow: 5px 10px 20px #b7bdbf;
}

svg.tick-icon-for-course {
  color: #b7bdbf;
}

p.course-description-text {
  color: white;
  font-family: 'Inter';
  font-size: 15px;
  padding: 0 26%;
}

h1.display-4.fw-bold {
  font-family: 'Inter';
  color: black;
  font-size: 40px;
}

.d-flex.flex-column.justify-content-center.col-md-6 {
  padding: 50px;
}

.learning-container.row {
  display: flex;
  align-items: center;
}

.testimonial-container-course.col-md-6 {
  padding: 50px;
}

h1.what-you-doing-heading {
  font-size: 36px;
  color: black;
  font-family: 'Inter';
}

h4.what-you-doing-text {
  font-size: 17px;
  padding: 0 20%;
  padding-bottom: 5%;
  color: #495057;
  font-family: "Inter";
}

.certificate.col-md-3 {
  display: flex;
  justify-content: center;
}

.tab-certificate {
  display: flex;
  column-gap: 10px;
  padding: 10px;
}

.tab.active-tab-certificate {
  display: flex;
  column-gap: 10px;
}

.tabs-header.vertical-tabs {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.tab.active-tab-certificate {
  border: 1px solid #b8bdbf;
  padding: 10px;
  border-radius: 4px;
}

h5.feature-text-heading {
  margin: 0;
  font-family: inter;
  font-size: 18px;
  border-bottom: 4px;
  font-weight: 700;
  text-align: start;
}

p.course-feature-text {
  font-size: 15px;
  text-align: start;
}

.course-page-container {
  background-color: white;
}

@media (max-width: 575.98px) {
  .container.feature-container {
    display: flex;
    padding: 350px 80px;
    column-gap: 20px;
    padding-bottom: 30px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .container.feature-container {
    display: flex;
    padding: 350px 0px;
    column-gap: 20px;
    padding-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .container.feature-container {
    display: flex;
    padding: 350px 0px;
    column-gap: 20px;
    padding-bottom: 30px;
  }
}