.reset-event-style {
    width: 14vh !important;
    border-radius: 5px !important;
    height: 6vh !important;
    background-color: rgb(67, 62, 62) !important;
    color: white;
}

.event-reset-btn {
    padding-top: 1.5vh !important;
    border-radius: 5px !important;
}

.createFormPage .container {
    margin-top: 2rem;
    background-color: #fff;
    padding: 3rem 2rem;
}

.createFormPage .container .row {
    margin-bottom: 1.5rem;
}

.createFormPage__header {
    margin-bottom: 2rem;
}

.createFormPage__header--title {
    font-size: 1.9rem;
    font-family: Arial, sans-serif;
    font-weight: 600;
}

.createFormPage .adduser--title {
    font-family: Arial, sans-serif;
    font-weight: 600;
}

.createFormPage .primeSelect {
    width: 100%;
}

.createFormPage label {
    display: block;
    font-weight: 600;
    font-size: 0.875rem;
}

.createFormPage input,
.createFormPage select,
.createFormPage textarea {
    outline: none !important;
    box-shadow: none !important;
    padding: 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 6px;
}

.createFormPage input::placeholder,
.createFormPage select::placeholder,
.createFormPage textarea::placeholder {
    color: #6c757d;
}

.createFormPage__title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.createFormPage .btnContainer {
    margin-top: 2rem;
    text-align: end;
}

.createFormPage .previewImage {
    margin-top: 2rem;
    height: 200px;
    width: 100%;
    object-fit: contain;
}

.remove-button {
    display: flex;
    justify-content: end;
}

.eventss_templesList .card img {
    height: 330px;
    border-radius: 2%;
}

.reset-event-style {
    background-color: lightgray;
    padding: 3px;
    width: 40%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.eventCardss {
    background-color: #f0f8ff;
    padding: 1rem;
    transition: background-color 0.3s ease;
    height: auto;
    min-height: 100%;
    border: none;
}

.eventTitle {
    font-size: 1.5rem;
    font-weight: bold;
    color: #4682b4;
    // margin-bottom: .7rem;
    transition: color 0.3s ease;
}

.eventTextcard {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    // margin-bottom: 1rem;
}
.mt-4.row {
    padding-top: 6%;
}