.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.slide-section {
  background-image: linear-gradient(90deg, #2f57ef7d 0%, #c586ee52 100%),
    linear-gradient(90deg, #2f57efbf 0%, #c586eeab 100%);
  color: white;
  text-align: center;
  padding: 50px 20px;
  /* height: 50vh; */
  margin-bottom: 30px;
}

.slide-section h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: white !important;
  margin-bottom: 15px;
}

.slide-section p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.course-filter-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.simple-search-input {
  padding: 0 10px !important;
  border-radius: 5px !important;
  border: 1px solid #ccc;
  margin-right: 10px;
  width: 250px !important;
}

.simple-search-btn {
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 5px !important;
  cursor: pointer;
  margin-right: 10px;
}

.simple-search-btn:hover {
  background-color: #40a9ff;
}

.course-cards-container {
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 100%;
  margin-bottom: 5%;
}

.course-cards-row {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.course-card {
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  background-color: white !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.course-card:hover {
  transform: scale(1.05);
}

.course-card-image {
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.course-card .ant-card-body {
  padding: 10px;
}

.course-card .ant-card-meta-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.course-card .ant-card-meta-description p {
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.author-info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}

.author-info span {
  font-size: 0.9rem;
}

.learn-more-btn {
  margin-top: 10px;
  width: 100%;
  background-color: #2f57ef;
  color: white;
  border: none;
  transition: background-color 0.3s ease;
}

.learn-more-btn:hover {
  background-color: #1d45d9;
}



.bus-search-bar {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  border-radius: 30px;
  color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
  width: 75%;
  margin-top: 120px;
}

.input-section {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 30px;
  margin-right: 10px;
  flex-grow: 1;
  color: black;
}

.input-field {
  display: flex;
  align-items: center;
  margin-right: 25px;
  text-align: center;
}

.input-field span {
  margin-left: 8px;
  font-size: 14px;
}
.icon {
  font-size: 2px !important;
  color: #444;
  margin-right: 8px;
}

.input-field input {
  border: none;
  outline: none;
  font-size: 2px;
  color: #444;
  background-color: transparent;
}

.search-button {
  background-color: #d43737;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #385ad1;
}


@media (max-width: 600px) {
  .bus-search-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .input-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px; /* Adds spacing between input fields */
  }

  .input-field {
    width: 100%;
  }

  .input-field input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
  }

  .search-button {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background-color: #b52e2e;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 14px;
    margin-top: 10px;
  }

  .search-button:hover {
    background-color: #b52e2e;
  }
}



@media (max-width: 900px) {
  .bus-search-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .input-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px; /* Adds spacing between input fields */
  }

  .input-field {
    width: 100%;
  }

  .input-field input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
  }

  .search-button {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background-color: #b52e2e;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 14px;
    margin-top: 10px;
  }

  .search-button:hover {
    background-color: #b52e2e;
  }
}
